<template>
    <div class="bg-white rounded-lg px-[8%] py-[50px]">
        <LoadingComponent :isLoading="loading" />
        <div class="mb-5 p-5">
            <h2 class="font-medium md:text-2xl text-base md:mb-10 mb-3">路由資訊</h2>
            <el-form ref="form" class="relative" :model="form" :rules="rules">
                <div class="grid grid-cols-2 gap-4">
                    <el-form-item class="w-full" prop="name">
                        <label>
                            路由名稱 <span class="text-red-500">{{ $Debug ? "name" : "" }}</span>
                            <el-input v-model="form.name" class="w-full"></el-input>
                        </label>
                    </el-form-item>
                    <el-form-item class="w-full" prop="key">
                        <label>
                            路由Key <span class="text-red-500">{{ $Debug ? "key" : "" }}</span>
                            <el-input v-model="form.key" class="w-full"></el-input>
                        </label>
                    </el-form-item>
                </div>
                <div class="flex justify-center">
                    <el-form-item class="flex-1 mr-5" prop="customer_key">
                        <label>
                            客製化路由Key(非必填) <span class="text-red-500">{{ $Debug ? "customer_key" : "" }}</span>
                            <el-input v-model="form.customer_key" class="w-full"></el-input>
                        </label>
                    </el-form-item>
                    <el-form-item class="flex-1" prop="is_menu">
                        <label>
                            是否為子路由 <span class="text-red-500">{{ $Debug ? "is_menu" : "" }}</span> <br />(判斷是否顯示在 menu 下拉)
                            <el-switch
                                v-model="form.is_menu"
                                class="ml-2"
                                active-color="#FF5733"
                                inactive-color="#e5e5e5"
                                :active-value="1"
                                :inactive-value="0">
                            </el-switch>
                            <!-- <el-select v-model="form.is_menu" class="w-full" placeholder="請選擇是否為子路由">
                                <el-option label="是" :value="true"></el-option>
                                <el-option label="否" :value="false"></el-option>
                            </el-select> -->
                        </label>
                    </el-form-item>
                    <el-form-item class="flex-1" prop="is_child_feature">
                        <label>
                            是否為頁面子功能 <span class="text-red-500">{{ $Debug ? "is_child_feature" : "" }}</span>
                            <br />(用來識別是否為主頁面中的子功能)
                            <el-switch
                                v-model="form.is_child_feature"
                                class="ml-2"
                                active-color="#FF5733"
                                inactive-color="#e5e5e5"
                                :active-value="1"
                                :inactive-value="0">
                            </el-switch>
                            <!-- <el-select v-model="form.is_menu" class="w-full" placeholder="請選擇是否為子路由">
                                <el-option label="是" :value="true"></el-option>
                                <el-option label="否" :value="false"></el-option>
                            </el-select> -->
                        </label>
                    </el-form-item>
                </div>
                <div class="grid grid-cols-1 gap-4">
                    <el-form-item class="w-full" prop="setting">
                        <label>
                            保留欄位Key JSON 格式 (非必填) <span class="text-red-500">{{ $Debug ? "route_set (json 欄位)" : "" }}</span>
                            <el-input v-model="form.setting" type="textarea" rows="5" class="w-full"></el-input>
                        </label>
                    </el-form-item>
                </div>
            </el-form>
        </div>
        <div class="border-b-2 border-gray-100"></div>
        <div class="p-5">
            <div class="flex items-center">
                <h2 class="font-medium md:text-2xl text-base mr-5">
                    子項功能控制 <span class="text-red-500">{{ $Debug ? "child_features (json 欄位)" : "" }}</span>
                </h2>
                <button class="text-red-500 font-light text-base" @click.prevent="addFeature()">
                    <i class="fas fa-plus"></i>
                    <span class="text-red-500 ml-2">新增子項功能</span>
                </button>
            </div>
            <div v-if="form.child_features.length > 0" class="border rounded-lg mt-5">
                <table class="w-full">
                    <thead class="border-b border-gray-100">
                        <tr class="h-[50px]">
                            <th>
                                路由名稱 <span class="text-red-500">{{ $Debug ? "name" : "" }}</span>
                            </th>
                            <th>
                                欄位路由 Key <span class="text-red-500">{{ $Debug ? "key" : "" }}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="p-2">
                        <tr v-for="(feature, index) in form.child_features" :key="index" class="pt-2 h-[50px]">
                            <td class="px-5 py-2">
                                <el-input v-model="form.child_features[index].name" class="w-full" placeholder="請輸入顯示名稱"> </el-input>
                            </td>
                            <td>
                                <el-input v-model="form.child_features[index].key" class="w-full" placeholder="請輸入key"> </el-input>
                            </td>
                            <td>
                                <button @click.prevent="removeFeature(index)">
                                    <button class="min-w-[100px] text-sm"><i class="far fa-trash-alt"></i></button>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="items-center">
                <h2 class="font-medium md:text-2xl text-base mr-5 my-[40px]">API 權限</h2>
                <div>
                    <div class="label font-medium text-base">GET</div>
                    <el-checkbox-group v-model="checkGets">
                        <el-checkbox v-for="(i, idx) in groupTypes.gets" :key="idx" :label="i.val">{{ i.uri }}</el-checkbox>
                    </el-checkbox-group>
                </div>
                <div>
                    <div class="label font-medium text-base">POST</div>
                    <el-checkbox-group v-model="checkPosts">
                        <el-checkbox v-for="(i, idx) in groupTypes.posts" :key="idx" :label="i.val">{{ i.uri }}</el-checkbox>
                    </el-checkbox-group>
                </div>
                <div>
                    <div class="label font-medium text-base">UPDATE</div>
                    <el-checkbox-group v-model="checkUpdates">
                        <el-checkbox v-for="(i, idx) in groupTypes.updates" :key="idx" :label="i.val">{{ i.uri }}</el-checkbox>
                    </el-checkbox-group>
                </div>
                <div>
                    <div class="label font-medium text-base">DELETE</div>
                    <el-checkbox-group v-model="checkDeletes">
                        <el-checkbox v-for="(i, idx) in groupTypes.deletes" :key="idx" :label="i.val">{{ i.uri }}</el-checkbox>
                    </el-checkbox-group>
                </div>
            </div>
            <div class="flex justify-center my-5">
                <btn
                    v-if="!isUpdate"
                    :disabled="loading"
                    :color="loading ? 'disabled:text-white' : 'text-black border border-black mr-5'"
                    @onClick="$router.push({ name: 'permission_routes' })">
                    取消
                </btn>
                <btn
                    v-else
                    v-permission="['delete']"
                    :disabled="loading"
                    :color="loading ? 'disabled:text-white' : 'text-black border border-black mr-5'"
                    @onClick="onDelete">
                    刪除路由
                </btn>
                <btn v-if="isUpdate" v-permission="['update']" v-loading="loading" :disabled="loading" @onClick="onSubmit('form')">儲存變更</btn>
                <btn v-else v-permission="['create']" v-loading="loading" :disabled="loading" @onClick="onSubmit('form')">儲存變更</btn>
            </div>
            <!-- 路由使用角色與權限 -->
            <div class="items-center">
                <h2 class="font-medium md:text-2xl text-base mr-5 my-[40px]">路由使用角色與權限</h2>
                <div v-for="item in form.roles" :key="item.id" class="flex w-[100%] justify-between roles">
                    <div class="w-[100px]">{{ item.name }}</div>
                    <div class="crud w-[300px] text-left">
                        <span v-if="convertToBinary(item.pivot.operation).read">讀取 </span>
                        <span v-if="convertToBinary(item.pivot.operation).create">新增</span>
                        <span v-if="convertToBinary(item.pivot.operation).update">編輯</span>
                        <span v-if="convertToBinary(item.pivot.operation).delete">刪除</span>
                    </div>
                    <div class="w-[50px] text-red-500" @click="goEditRole(item.id)">
                        <i class="far fa-edit"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
// 導入自定義 按鈕
import Btn from "@/components/Button.vue";
import LoadingComponent from "@/components/Loading.vue";

export default {
    name: "PermissionRouteForm",
    components: {
        Btn,
        LoadingComponent,
    },
    computed: {
        groupTypes() {
            const results = {
                gets: [],
                posts: [],
                updates: [],
                deletes: [],
            };
            this.allAPI.forEach((i) => {
                if (i.methods.includes("POST")) {
                    results.posts.push({ uri: i.uri, val: JSON.stringify(i) });
                }
                if (i.methods.includes("GET")) {
                    results.gets.push({ uri: i.uri, val: JSON.stringify(i) });
                }
                if (i.methods.includes("PATCH") || i.methods.includes("PUT")) {
                    results.updates.push({ uri: i.uri, val: JSON.stringify(i) });
                }
                if (i.methods.includes("DELETE")) {
                    results.deletes.push({ uri: i.uri, val: JSON.stringify(i) });
                }
            });
            return results;
        },
    },
    data() {
        return {
            id: 0,
            form: {
                child_features: [],
                roles: [],
            },
            // 路由使用角色與權限
            roles: [],
            allAPI: [],
            // 選中的 api
            checkGets: [],
            checkPosts: [],
            checkUpdates: [],
            checkDeletes: [],
            rules: {
                name: [{ required: true, message: "路由名稱為必填", trigger: "blur" }],
                key: [{ required: true, message: "路由key為必填", trigger: "blur" }],
                is_menu: [{ required: true, message: "請選擇此路由是否為子路由", trigger: "change" }],
            },
            // 顯示刪除彈窗
            showDialog: false,
            // 判斷是否為更新
            isUpdate: false,
            loading: false,
        };
    },
    methods: {
        ...mapActions("apiStore", ["errorCallback"]),
        /**
         * 新增子項功能
         */
        addFeature() {
            this.form.child_features.push({ name: "", key: "" });
        },
        /**
         * 刪除子項功能
         * @param { type Number(數字) } index
         */
        removeFeature(index) {
            this.form.child_features.splice(index, 1);
        },
        /**
         * 表單發送
         * @param { type String(字串) } formName 傳送表單名稱
         * @example ref="form"
         */
        async onSubmit(formName) {
            try {
                // 判斷是否有空字串 移除空字串 或 任何空值
                // const filterChildFeatures = _reject(this.form.child_features, _isEmpty);
                // 將過濾後結果回傳 如果是非空陣列則寫入 JSON字串 空陣列則寫入 null
                // this.$set(this.form, "childFeatures", filterChildFeatures.length <= 0 ? null : JSON.stringify(filterChildFeatures));
                // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
                await this.$refs[formName].validate();
                if (this.isUpdate) {
                    this.update();
                } else {
                    this.create();
                }
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "表單驗證失敗",
                });
            }
        },
        /**
         * 新增路由
         */
        async create() {
            this.loading = true;
            try {
                const request = {
                    ...this.form,
                    api: {
                        read: this.checkGets.map((i) => JSON.parse(i)),
                        create: this.checkPosts.map((i) => JSON.parse(i)),
                        update: this.checkUpdates.map((i) => JSON.parse(i)),
                        delete: this.checkDeletes.map((i) => JSON.parse(i)),
                    },
                };

                if (this.$route.params.parentId !== "root") {
                    request.parent_id = this.$route.params.parentId;
                }

                await this.$api.CreatePermissionsApi(request);
                this.$message({
                    type: "success",
                    message: "新增成功",
                });

                this.$router.push({ name: "permission_routes" });
                this.loading = false;
            } catch (err) {
                this.loading = false;
                this.errorCallback({ err });
            }
        },
        /**
         * 更新路由
         */
        async update() {
            this.loading = true;
            try {
                const req = {
                    key: this.form.key,
                    name: this.form.name,
                    is_menu: this.form.is_menu,
                    is_child_feature: this.form.is_child_feature,
                    setting: JSON.parse(this.form.setting),
                    child_features: this.form.child_features,
                    customer_key: this.form.customer_key ? this.form.customer_key : "",
                    api: {
                        read: this.checkGets.map((i) => JSON.parse(i)),
                        create: this.checkPosts.map((i) => JSON.parse(i)),
                        update: this.checkUpdates.map((i) => JSON.parse(i)),
                        delete: this.checkDeletes.map((i) => JSON.parse(i)),
                    },
                };
                await this.$api.UpdatePermissionsApi(this.id, req);
                this.$message({
                    type: "success",
                    message: "儲存成功",
                });
                this.setDefault();
                this.loading = false;
            } catch (err) {
                this.loading = false;
                this.errorCallback({ err });
            }
        },
        /**
         * 刪除路由
         * @param { type Number(數字) } id 路由 id
         * @param { type String(字串) } name 路由名稱 name
         */
        async onDelete() {
            this.loading = true;
            const opts = {
                title: "刪除資料",
                message: `你確認刪除 <span class="text-red-600"> ${this.form.name} </span> 路由嗎？`,
            };
            this.$pop
                .popConfirm(opts)
                .then(
                    async () => {
                        await this.$api.DeletePermissionsApi(this.id);
                        this.$message({
                            type: "success",
                            message: "刪除路由成功",
                        });
                        this.$router.push({ name: "permission_routes" });
                    },
                    () => {
                        return;
                    }
                )
                .catch((err) => {
                    this.errorCallback({ err });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        // 設定起始資料
        async setDefault() {
            // const other_setting = {
            //     test: [
            //         { name: "test1", key: "test key" },
            //         { name: "test2", key: "test2 key" }
            //     ],
            //     test2: {
            //         name: "test2 name",
            //         key: "test2 key"
            //     }
            // };
            // this.form = {
            //     name: "測試編輯",
            //     key: "test_update_key",
            //     is_menu: true,
            //     customer_key: "test_custom_key",
            //     other_setting: JSON.stringify(other_setting)
            // };
            // this.childFeatures = [
            //     { name: "顯示電話", key: "providerListShowPhone" },
            //     { name: "顯示存摺", key: "providerListShowBank" },
            //     { name: "顯示身分證", key: "providerListShowID" }
            // ];
            const id = this.$route.params.id;
            this.loading = true;
            try {
                const { data } = await this.$api.GetPermissionsIDApi(id);
                this.form = { ...data, setting: JSON.stringify(data.setting) };
                this.setApiDatas(data.api);
                this.loading = false;
            } catch {
                this.$message({
                    type: "error",
                    message: "獲取資料失敗",
                });
                this.loading = false;
            }
        },
        setApiDatas(api) {
            this.checkPosts = [];
            this.checkGets = [];
            this.checkUpdates = [];
            this.checkDeletes = [];
            api.create?.forEach((i) => {
                this.checkPosts.push(JSON.stringify(i));
            });
            api.read?.forEach((i) => {
                this.checkGets.push(JSON.stringify(i));
            });
            api.update?.forEach((i) => {
                this.checkUpdates.push(JSON.stringify(i));
            });
            api.delete?.forEach((i) => {
                this.checkDeletes.push(JSON.stringify(i));
            });
        },
        // 編輯角色
        async goEditRole(id) {
            this.$router.push({ name: "permission_role_set", params: { id } });
        },
        //  operation 轉換為二進制帶出crud格式
        convertToBinary(number) {
            let num = number;
            let binary = (num % 2).toString();
            for (; num > 1; ) {
                num = parseInt(num / 2);
                binary = (num % 2) + binary;
            }
            binary = binary.split("");
            if (binary.length < 4) {
                for (let i = binary.length; i < 4; i++) {
                    binary.splice(0, 0, "0");
                }
            }
            return {
                read: binary[3] == 1,
                create: binary[2] == 1,
                update: binary[1] == 1,
                delete: binary[0] == 1,
            };
        },
    },
    async mounted() {
        if (this.$route.name === "permission_route_set" && this.$route.params.id !== undefined) {
            this.id = this.$route.params.id;
            this.isUpdate = true;
            this.setDefault();
        }
        await this.$api.GetAllApi().then((res) => {
            this.allAPI = [...res.data.routers];
        });
    },
};
</script>
<style lang="scss" scoped>
.roles {
    font-size: 18px;
    margin-top: 15px;
    .crud {
        span {
            padding: 0 10px;
            height: 15px;
            border-right: 1px solid #000;
            &:last-child {
                border-right: none;
            }
        }
    }
}
</style>
